@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131524;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* Navbar */

.navbarNav {
  width: 100%;
  margin-top: 0.75rem;
  overflow-y: hidden;
}

.navbarListItems {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbarListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarListItemName,
.navbarListItemNameActive {
  margin-top: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  color: #8f8f8f;
}
.navbarListItemNameActive {
  color: #2c2c2c;
}